
import { defineComponent } from "vue";
import { mapState } from "vuex";
import axios from "axios";
import moment, { Moment } from "moment-timezone";
import { without } from "lodash";
import AppPage from "../components/AppPage.vue";
import Preloader from "../components/Preloader.vue";
import DataTable from "../components/DataTable.vue";
import DateRangeInput from "../components/DateRangeInput.vue";
import SubsetInput from "../components/SubsetInput.vue";
import PushStats from "../components/PushStats.vue";
interface DataModel {
  loading: boolean;
  filter: Filter;
  options: { [k in TFilterOption]: Array<Option> };
  columns: Array<Column>;
  rows: Array<any>;
  totalRows: number;
  totals: any;
  isPushTab: boolean;
}
interface ComputedModel {
  query: any;
  grouping: any;
  user: any;
}
interface MethodsModel {
  setColumns(columns: Column[]): void;
  setSorting(): void;
  storeColDir(): void;
  load(): void;
  clear(): void;
  download(): void;
  articlesLookup(): void;
  pageTypesLookup(): void;
  filterOptions(collection: TFilterOption, query?: string): void;
}
interface Filter {
  period: Moment[];
  timezone: string;
  campaign_ids: Array<number>;
  article_ids: Array<number>;
  geo_ids: Array<number>;
  design_ids: Array<number>;
  device_ids: Array<number>;
  page_type_ids: Array<number>;
  sid1: Array<string>;
  sid2: Array<string>;
  sid3: Array<string>;
  sid4: Array<string>;
  pagination: Pagination;
  sorting: Sorting;
  domain_names: Array<string>;
  utm_campaign: Array<string>;
  utm_medium: Array<string>;
  utm_content: Array<string>;
  utm_term: Array<string>;
}
interface Sorting {
  column: string;
  direction: "asc" | "desc";
}
interface Option {
  id: number | string;
  name: string;
}
interface Column extends Option {
  selected: boolean;
  freezed?: boolean;
  direction?: "asc" | "desc";
}
interface Pagination {
  limit: number;
  offset: number;
}
type TFilterOption = typeof filterFieldsIds[number];
const filterFieldsIds = [
  "sid1",
  "sid2",
  "sid3",
  "sid4",
  "designs",
  "geos",
  "campaigns",
  "articles",
  "page_types",
  "domain_names",
  "utm_campaign",
  "utm_medium",
  "utm_content",
  "utm_term",
  "devices",
] as const;

const groupingFieldIds = [
  "date",
  "campaign_name",
  "article_name",
  "geo_name",
  "design_name",
  "device_name",
  "page_type_name",
  "sid1",
  "sid2",
  "sid3",
  "sid4",
  "utm_campaign",
  "utm_medium",
  "utm_content",
  "utm_term",
];
const allColumns = [
  {
    id: "date",
    name: "Дата",
    selected: true,
    sortable: true,
    direction: "desc",
    group: 1,
  },
  {
    id: "campaign_name",
    name: "Кампания",
    selected: false,
    sortable: true,
    direction: "desc",
    type: "text",
    group: 1,
  },
  {
    id: "article_name",
    name: "Статья",
    selected: false,
    sortable: true,
    direction: "desc",
    type: "text",
    group: 1,
  },
  {
    id: "geo_name",
    name: "Гео",
    selected: false,
    sortable: true,
    direction: "desc",
    type: "text",
    group: 1,
  },
  {
    id: "design_name",
    name: "Дизайн",
    selected: false,
    sortable: false,
    direction: "desc",
    type: "text",
    group: 1,
  },
  {
    id: "device_name",
    name: "Устройство",
    selected: false,
    sortable: false,
    direction: "desc",
    type: "text",
    group: 1,
  },
  {
    id: "page_type_name",
    name: "Тип страницы",
    selected: false,
    sortable: false,
    direction: "desc",
    type: "text",
    group: 1,
  },
  { id: "sid1", name: "SID 1", selected: false, sortable: true, group: 1 },
  { id: "sid2", name: "SID 2", selected: false, sortable: true, group: 1 },
  { id: "sid3", name: "SID 3", selected: false, sortable: true, group: 1 },
  { id: "sid4", name: "SID 4", selected: false, sortable: true, group: 1 },
  {
    id: "utm_campaign",
    name: "utm_campaign",
    selected: false,
    sortable: true,
    group: 1,
  },
  {
    id: "utm_content",
    name: "utm_content",
    selected: false,
    sortable: true,
    group: 1,
  },
  {
    id: "utm_term",
    name: "utm_term",
    selected: false,
    sortable: true,
    group: 1,
  },
  {
    id: "utm_medium",
    name: "utm_medium",
    selected: false,
    sortable: true,
    group: 1,
  },
  { id: "bots", name: "Боты", selected: true, sortable: true, group: 2 },
  {
    id: "visits",
    name: "Переходы",
    selected: true,
    sortable: true,
    group: 2,
  },
  { id: "users", name: "Уники", selected: true, sortable: true, group: 2 },
  { id: "views", name: "Показы", selected: true, sortable: true, group: 2 },
  { id: "clicks", name: "Клики", selected: true, sortable: true, group: 2 },
  { id: "epc", name: "EPC", selected: true, sortable: true, group: 2 },
  { id: "ctr", name: "CTR, %", selected: true, sortable: true, group: 2 },
  { id: "cr", name: "CR, %", selected: true, sortable: true, group: 2 },
  {
    id: "approve",
    name: "Аппрув, %",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "valid_leads",
    name: "Лиды",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "pending_leads",
    name: "Обработка, шт.",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "approved_leads",
    name: "Апрув, шт.",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "rejected_leads",
    name: "Отмена, шт.",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "trash_leads",
    name: "Треш, шт.",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "waiting_usd",
    name: "В ожидании, USD",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "income_usd",
    name: "Доход, USD",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "rejected_usd",
    name: "Отклонено, USD",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "extra_monetized_usd",
    name: "Домонет. USD",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "waiting_euro",
    name: "В ожидании, EUR",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "income_euro",
    name: "Доход, EUR",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "rejected_euro",
    name: "Отклонено, EUR",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "extra_monetized_euro",
    name: "Домонет. EUR",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "waiting_rub",
    name: "В ожидании, RUB",
    selected: false,
    sortable: true,
    group: 2,
  },
  {
    id: "income_rub",
    name: "Доход, RUB",
    selected: false,
    sortable: true,
    group: 2,
  },
  {
    id: "rejected_rub",
    name: "Отклонено, RUB",
    selected: false,
    sortable: true,
    group: 2,
  },
  {
    id: "extra_monetized_rub",
    name: "Домонет. RUB",
    selected: false,
    sortable: true,
    group: 2,
  },
];
function getDefaultFilter(): Filter {
  return {
    period: [moment().endOf("day").subtract(29, "days"), moment().endOf("day")],
    timezone: moment.tz.guess(),
    campaign_ids: [],
    article_ids: [],
    geo_ids: [],
    design_ids: [],
    device_ids: [],
    page_type_ids: [],
    sid1: [],
    sid2: [],
    sid3: [],
    sid4: [],
    pagination: { limit: 10, offset: 0 },
    sorting: { column: "date", direction: "desc" },
    domain_names: [],
    utm_campaign: [],
    utm_medium: [],
    utm_content: [],
    utm_term: [],
  };
}
function getStoredColumns() {
  const json = localStorage.getItem("statistics.columns");
  if (!json) {
    return allColumns;
  } else {
    const storedColumns = JSON.parse(json).reduce(
      (memo: any, c: any) => ({
        ...memo,
        [c.id]: c,
      }),
      {}
    );
    return allColumns.map((c) =>
      storedColumns[c.id]
        ? {
            ...c,
            direction: storedColumns[c.id].direction,
            selected: storedColumns[c.id].selected,
          }
        : c
    );
  }
}
function storeColumns(columns: Array<Column>) {
  localStorage.setItem("statistics.columns", JSON.stringify(columns));
}
export default defineComponent<DataModel & ComputedModel & MethodsModel>({
  components: {
    AppPage,
    Preloader,
    DataTable,
    DateRangeInput,
    SubsetInput,
    PushStats,
  },
  data(): DataModel {
    return {
      loading: false,
      filter: getDefaultFilter(),
      options: {
        campaigns: [],
        articles: [],
        geos: [],
        designs: [],
        devices: [],
        page_types: [],
        sid1: [],
        sid2: [],
        sid3: [],
        sid4: [],
        domain_names: [],
        utm_campaign: [],
        utm_medium: [],
        utm_content: [],
        utm_term: [],
      },
      columns: getStoredColumns(),
      rows: [],
      totalRows: 0,
      totals: {},
      isPushTab: false,
    };
  },
  computed: {
    query() {
      return {
        q: JSON.stringify({
          filter: this.filter,
          grouping: this.grouping,
          sorting: this.filter.sorting,
          pagination: this.filter.pagination,
        }),
      };
    },
    grouping() {
      return this.columns.find(
        (it: Column) => groupingFieldIds.includes(String(it.id)) && it.selected
      )?.id;
    },
    ...mapState(["user"]),
  },
  watch: {
    columns(newColumns) {
      storeColumns(newColumns);
    },
  },
  async created() {
    this.setSorting();
    this.load();
    this.pageTypesLookup();
    this.articlesLookup();
    without(filterFieldsIds, "articles", "page_types").forEach(
      (it: TFilterOption) => this.filterOptions(it)
    );
  },
  mounted() {
    this.$watch("filter.sorting", this.load);
    this.$watch("filter.pagination", this.load);
  },
  methods: {
    setColumns(newColumns: Column[]) {
      const selectedGrouping = newColumns.find(
        (it: Column) =>
          groupingFieldIds.includes(String(it.id)) &&
          it.selected &&
          !this.columns.find((c: Column) => c.id === it.id)?.selected
      );
      if (!selectedGrouping) {
        this.columns = newColumns;
        return;
      }
      this.columns = newColumns.map((c) => ({
        ...c,
        selected:
          c.id === selectedGrouping.id ||
          (!groupingFieldIds.includes(String(c.id)) && c.selected),
      }));
      this.setSorting();
      this.load();
    },
    setSorting() {
      const { sorting } = this.filter;
      sorting.column = groupingFieldIds.includes(sorting.column)
        ? this.grouping
        : sorting.column;
      sorting.direction =
        this.columns.find((it: Column) => it.id === sorting.column)
          ?.direction || sorting.direction;
    },
    storeColDir() {
      const { sorting } = this.filter;
      this.columns.map((it: Column) => {
        if (it.id === sorting.column) {
          it.direction = sorting.direction;
        }
      });
      storeColumns(this.columns);
    },
    async articlesLookup(query = "") {
      const { data } = await axios.get("/api/news/lookup", {
        params: { q: JSON.stringify({ query }) },
      });
      this.options.articles = data;
    },
    async pageTypesLookup(query = "") {
      const { data } = await axios.get("/api/page-types/lookup", {
        params: { q: JSON.stringify({ query }) },
      });
      this.options.page_types = data;
    },
    async filterOptions(collection: TFilterOption, query = "") {
      const { data } = await axios.get("/api/" + collection + "/lookup", {
        params: { q: JSON.stringify({ query }) },
      });
      this.options[collection] = data;
    },
    async load() {
      this.loading = true;
      try {
        const { data } = await axios.get("/api/statistics/get", {
          params: this.query,
        });
        this.rows = data.rows;
        this.totalRows = data.totalRows;
        this.totals = data.totals;
        if (this.totalRows < this.filter.pagination!.offset) {
          this.filter.pagination!.offset = 0;
        }
      } catch (e: any) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
      this.loading = false;
    },
    clear() {
      this.filter = getDefaultFilter();
      this.setSorting();
      this.load();
    },
    download() {
      window.open(
        `/api/statistics/download?q=${encodeURIComponent(this.query.q)}`,
        "_blank"
      );
    },
  },
});
