
import { defineComponent } from "vue";
import axios from "axios";
import moment, { Moment } from "moment-timezone";

import Preloader from "../components/Preloader.vue";
import DataTable from "../components/DataTable.vue";
import DateRangeInput from "../components/DateRangeInput.vue";
import SubsetInput from "../components/SubsetInput.vue";

interface DataModel {
  loading: boolean;
  filter: Filter;
  campaigns: Array<Option>;
  geos: Array<any>;
  columns: Array<Column>;
  rows: Array<any>;
  totalRows: number;
  totals: any;
}
interface ComputedModel {
  query: any;
  effectiveGroupingFieldId: any;
}
interface MethodsModel {
  lookup(): void;
  lookupGeos(): void;
  load(): void;
  clear(): void;
  download(): void;
  setColumns(columns: Column[]): void;
  getGroupingSelected(first: any, second: any): any;
}
interface Filter {
  period: Moment[];
  timezone: string;
  campaign_ids: Array<number>;
  pagination: null | Pagination;
  sorting: Sorting;
  geo_ids: Array<number>;
}
interface Sorting {
  column: string;
  direction: string;
}
interface Option {
  id: number | string;
  name: string;
}
interface Column extends Option {
  selected: boolean;
  freezed?: boolean;
}
interface Pagination {
  limit: number;
  offset: number;
}

function getDefaultFilter(): Filter {
  return {
    period: [moment().endOf("day").subtract(29, "days"), moment().endOf("day")],
    timezone: moment.tz.guess(),
    campaign_ids: [],
    geo_ids: [],
    pagination: { limit: 10, offset: 0 },
    sorting: { column: "date", direction: "desc" },
  };
}

const groupingFieldIds = ["date", "campaign_name", "geo"];

const allColumns = [
  { id: "date", name: "Дата", selected: true, sortable: true, group: 1 },
  {
    id: "campaign_name",
    name: "Потоки",
    selected: true,
    sortable: true,
    type: "text",
    group: 1,
  },
  { id: "geo", name: "Гео", selected: true, sortable: false, group: 1 },
  { id: "subscriptions", name: "Подписки", selected: true, sortable: false },
  { id: "unsubscriptions", name: "Отписки", selected: true, sortable: false },
  { id: "display", name: "Доставки", selected: true, sortable: false },
  { id: "clicks", name: "Клики", selected: true, sortable: false },
  { id: "valid_leads", name: "Лиды", selected: true, sortable: false },
  { id: "income_usd", name: "Доход, USD", selected: true, sortable: false },
];

function getStoredColumns() {
  const json = localStorage.getItem("statistics-push.columns");
  if (!json) {
    return allColumns;
  } else {
    const storedColumns = JSON.parse(json).reduce(
      (memo: any, c: any) => ({
        ...memo,
        [c.id]: c,
      }),
      {}
    );
    return allColumns.map((c) =>
      storedColumns[c.id]
        ? {
            ...c,
            selected: storedColumns[c.id].selected,
          }
        : c
    );
  }
}

function storeColumns(columns: Array<Column>) {
  localStorage.setItem("statistics-push.columns", JSON.stringify(columns));
}

export default defineComponent<DataModel & ComputedModel & MethodsModel>({
  components: {
    Preloader,
    DataTable,
    DateRangeInput,
    SubsetInput,
  },
  data() {
    return {
      loading: false,
      filter: getDefaultFilter(),
      campaigns: [],
      geos: [],
      columns: getStoredColumns(),
      rows: [],
      totalRows: 0,
      totals: [],
    };
  },
  computed: {
    query() {
      return {
        q: JSON.stringify({
          filter: this.filter,
          sorting: this.effectiveGroupingFieldId.includes(this.filter.sorting)
            ? this.filter.sorting
            : {
                column: this.effectiveGroupingFieldId[0],
                direction: this.filter.sorting.direction,
              },
          pagination: this.filter.pagination,
          grouping: this.effectiveGroupingFieldId || ["date"],
        }),
      };
    },
    effectiveGeos() {
      return this.geos.map((g) => ({
        ...g,
        id: g.iso_code,
        name: g.name,
      }));
    },
    effectiveGroupingFieldId() {
      return this.getGroupingSelected(
        groupingFieldIds,
        this.columns.filter((x) => x.selected)
      );
    },
  },
  watch: {
    columns(newColumns) {
      storeColumns(newColumns);
    },
  },
  created() {
    this.lookup();
    this.lookupGeos();
    this.load();
  },
  mounted() {
    this.$watch("filter.sorting", this.load);
    this.$watch("filter.pagination", this.load);
  },
  methods: {
    getGroupingSelected(first: any, second: any) {
      return first.reduce((acc: any, item: any) => {
        const val = second.find((el: any) => el.id === item);
        return val ? [...acc, val.id] : acc;
        // return val ? [...acc, undefined] : acc; // на случай, если надо вернуть undefined
      }, []);
    },
    setColumns(newColumns: Column[]) {
      const selectedColumns = newColumns.filter(
        (x: Column) => x.selected && groupingFieldIds.includes(x.id as string)
      );
      if (selectedColumns.length < 1) {
        return;
      }

      const selectedGrouping = this.getGroupingSelected(
        groupingFieldIds,
        newColumns.filter((x) => x.selected)
      );

      if (!selectedGrouping) {
        this.columns = newColumns;
        return;
      }

      this.filter.pagination!.offset = 0;
      this.load();
    },
    async lookup(query = "") {
      const { data } = await axios.get("/api/campaigns/lookup", {
        params: { q: JSON.stringify({ query }) },
      });
      this.campaigns = data;
    },
    async lookupGeos(query = "") {
      const { data } = await axios.get("/api/geos", {
        params: { q: JSON.stringify({ query }) },
      });
      this.geos = data;
    },
    async load() {
      this.loading = true;
      try {
        const { data } = await axios.get("/api/statistics/push/get", {
          params: this.query,
        });
        this.rows = data.rows;
        this.totals = data.totals;
        this.totalRows = data.totalRows;
      } catch (e: any) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
      this.loading = false;
    },
    clear() {
      this.filter = getDefaultFilter();
      this.lookupGeos();
      this.load();
    },
    download() {
      window.open(
        `/api/statistics/push/download?q=${encodeURIComponent(this.query.q)}`,
        "_blank"
      );
    },
  },
});
